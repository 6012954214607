export default function init(Countly) {
  Countly.init({
    app_key: process.env.NEXT_PUBLIC_COUNTLY_APP_KEY,
    url: process.env.NEXT_PUBLIC_COUNTLY_URL,
    session_update: 10,
    use_session_cookie: true,
    debug: false,
    require_consent: false,
    // namespace: 'react-demo',
    inactivity_time: 1,
    offline_mode: false,
    app_version: `${process.env.VERSION}-${process.env.git_hash.slice(0, 7)}`,
    // device_id: "cly-device-demo-id" //Set only if you want dont want to use countly generated device_id
  });
  // Since Countly is loaded and available, you can use synchronus or asynchronus calls, does not matter
  Countly.q.push([
    'group_features',
    {
      activity: ['sessions', 'events', 'views', 'location'],
      interaction: ['scrolls', 'clicks', 'crashes'],
      whereabouts: ['users'],
    },
  ]);

  /* if (typeof localStorage !== 'undefined') {
    var consents = localStorage.getItem('consents');

    if (consents) {
      Countly.q.push(['add_consent', JSON.parse(consents)]);
    } else {
      var consent = window.confirm('We are going to track you. Do you give your consent ?');
      consents = ['activity', 'interaction', 'whereabouts'];
      if (consent) {
        Countly.q.push(['add_consent', consents]);
        localStorage.setItem('consents', JSON.stringify(consents));
      } else {
        Countly.q.push(['remove_consent', consents]);
        localStorage.removeItem('consents');
      }
    }
  } */

  Countly.q.push(['enable_feedback', { widgets: ['widget-id-1', 'widget-id-2'] }]);
  Countly.q.push(['track_sessions']);
  Countly.q.push(['track_scrolls']);
  Countly.q.push(['track_clicks']);
  Countly.q.push(['track_links']);
  Countly.q.push(['track_errors']);
  Countly.q.push(['track_forms']);
  Countly.q.push(['collect_from_forms']);
  const campaignId = window.localStorage.getItem('campaignId');
  if (campaignId) Countly.q.push(['report_conversion', campaignId]);
  else Countly.q.push(['report_conversion']);
}
